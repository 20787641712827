import ReactDOM from 'react-dom/client'
import './index.css'
import { LanguageProvider } from './providers/LanguageProvider';

// import ReactGA from 'react-ga4';
import { TrackingProviderComponent } from './tracking/context';
import { RouterRoot } from './routerRoot';

const rootElement = document.getElementById('root')!

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <TrackingProviderComponent initialConfigs={[
      // {type: 'FASTLANE', id: '123'},
      // {type: 'META', id: '583403710904812'},
      {type: "GOOGLE_ANALYTICS", id: "G-27QZ6PZXLM"},
      // {type: "GOOGLE_ANALYTICS", id: 'G-EF4D7XNBYK'},
    ]}>
      <LanguageProvider>
        <RouterRoot/>
      </LanguageProvider>
    </TrackingProviderComponent>
  )
}
