import { createRouter, RouterProvider } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import * as Sentry from "@sentry/react";

const router = createRouter({
    routeTree,
    defaultPreload: 'intent',
    context: {
      marketplace: undefined!,
    },
  })
  
//   ReactGA.initialize(TRACKING_ID);
  
  router.subscribe('onResolved', () => {
    // ReactGA.send({ hitType: "pageview", page: router.state.location.pathname });
  });

  // Register things for typesafety
  declare module '@tanstack/react-router' {
    interface Register {
      router: typeof router
    }
  }

export function RouterRoot() {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.tanstackRouterBrowserTracingIntegration(router),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    return (
        <RouterProvider router={router} />
    );
  }