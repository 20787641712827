/* eslint-disable @typescript-eslint/no-explicit-any */
import { TrackingEvent } from "../events";
import BaseTrackingAdapter, { PixelConfig } from "./BaseTrackingAdapter";

export interface GoogleAnalyticsConfig extends PixelConfig {
    googleAdsId?: string;
    conversionLabel?: string;
}

// Google Analytics Adapter
export default class GoogleAnalyticsAdapter extends BaseTrackingAdapter {
    private static instances: Set<string> = new Set();
    private static scriptLoaded: boolean = false;

    protected config: GoogleAnalyticsConfig;
  
    constructor(config: GoogleAnalyticsConfig) {
      super(config);
      this.config = config;
    }

    async initialize(): Promise<void> {
      if (this.initialized) return;

      // Initialize dataLayer if not exists
      (window as any).dataLayer = (window as any).dataLayer || [];
      
      // Setup gtag function if not exists
      if (!(window as any).gtag) {
        function gtag(...args: any[]) {
          // @ts-expect-error window is not typed
          (window.dataLayer as any[]).push(args);
        }
        (window as any).gtag = gtag;
      }

      // Load GA4 script only once
      if (!GoogleAnalyticsAdapter.scriptLoaded) {
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js';
        script.async = true;
        document.head.appendChild(script);
        GoogleAnalyticsAdapter.scriptLoaded = true;
      }

      // Initialize this GA4 instance if not already initialized
      if (!GoogleAnalyticsAdapter.instances.has(this.config.id)) {
        const gtag = (window as any).gtag;
        gtag('js', new Date());
        gtag('config', this.config.id);
        GoogleAnalyticsAdapter.instances.add(this.config.id);
        console.log(`GA initialized for ${this.config.id}`);
      }

      if (this.config.googleAdsId && !GoogleAnalyticsAdapter.instances.has(this.config.googleAdsId)) {
        const gtag = (window as any).gtag;
        gtag('config', this.config.googleAdsId);
        GoogleAnalyticsAdapter.instances.add(this.config.googleAdsId);
        console.log(`Google Ads initialized for ${this.config.googleAdsId}`);
      }

      this.initialized = true;
    }

    async trackEvent(event: TrackingEvent): Promise<void> {
      if (!this.initialized) throw new Error('Google Analytics not initialized');

      const gtag = (window as any).gtag;
      if (!gtag) throw new Error('Google Analytics gtag function not found');

      console.log(`GA send ${event.type} for ${this.config.id}`, event.payload);
      // Common parameters for all events
      const baseParams = {
        send_to: this.config.id
      };

      switch (event.type) {
        case 'PAGE_VIEW':
          
          gtag('event', 'page_view', {
            ...baseParams,
            page_title: event.payload.title,
            page_location: event.payload.path,
            page_referrer: event.payload.referrer,
          });
          break;

        case 'VIEW_ITEM':
          gtag('event', 'view_item', {
            ...baseParams,
            currency: event.payload.currency,
            items: [{
              item_id: event.payload.productId,
              item_name: event.payload.name,
              price: event.payload.price,
              quantity: event.payload.quantity || 1
            }]
          });
          break;

        case 'ADD_TO_CART':
          gtag('event', 'add_to_cart', {
            ...baseParams,
            currency: event.payload.currency,
            items: [{
              item_id: event.payload.productId,
              item_name: event.payload.name,
              price: event.payload.price,
              quantity: event.payload.quantity
            }]
          });
          break;

        case 'REMOVE_FROM_CART':
          gtag('event', 'remove_from_cart', {
            ...baseParams,
            currency: event.payload.currency,
            items: [{
              item_id: event.payload.productId,
              quantity: event.payload.quantity
            }]
          });
          break;

        case 'BEGIN_CHECKOUT':
          gtag('event', 'begin_checkout', {
            ...baseParams,
            currency: event.payload.currency,
            value: event.payload.totalValue,
            items: event.payload.items.map(item => ({
              item_id: item.productId,
              item_name: item.name,
              price: item.price,
              quantity: item.quantity
            }))
          });
          break;

        case 'PURCHASE':
          gtag('event', 'purchase', {
            ...baseParams,
            transaction_id: event.payload.transactionId,
            currency: event.payload.currency,
            value: event.payload.totalValue,
            tax: event.payload.tax,
            shipping: event.payload.shipping,
            items: event.payload.items.map(item => ({
              item_id: item.productId,
              item_name: item.name,
              price: item.price,
              quantity: item.quantity
            }))
          });

          if(this.config.googleAdsId) {
            gtag('event', 'conversion', {
              'send_to': `${this.config.googleAdsId}/${this.config.conversionLabel}`,
              'value': event.payload.totalValue,
              'currency': event.payload.currency,
              'transaction_id': event.payload.transactionId,
            });
          }
          break;

        case 'CUSTOM':
          gtag('event', event.payload.eventName, {
            ...baseParams,
            ...event.payload,
          });
          break;
      }
    }
}