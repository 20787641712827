'use client';

import { RiAddLine, RiSubtractLine } from "@remixicon/react";
import { shopVar } from "@/lib/shopStyles";

interface QuantitySelectButtonProps {
    quantity: number;
    limit: number;
    loading: boolean;
    onIncrease: () => void;
    onDecrease: () => void;
    className?: string;
}

export default function QuantitySelectButton({
    quantity,
    limit,
    loading,
    onIncrease,
    onDecrease,
    className,
}: QuantitySelectButtonProps) {
    return (
        <div className={`flex items-center select-none ${className}`}>
            {quantity > 0 && <>
                <button
                    className="p-1 rounded-full"
                    style={{
                        opacity: loading ? 0.5 : 1,
                        color: shopVar('--shop-removeFromCartButtonTextColor'),
                        backgroundColor: shopVar('--shop-removeFromCartButtonColor'),
                    }}
                    disabled={loading} 
                    onClick={onDecrease}>
                    <RiSubtractLine size={20} color={'white'}/>
                </button>
                <span className="font-bold text-lg px-2" style={{
                    width: '2.5rem',
                    textAlign: 'center',
                    color: 'var(--shop-productQuantityTextColor)',
                }}>{quantity}</span>
            </>}

            <button
                className="p-1 rounded-full add-quantity-button"
                style={{
                    opacity: quantity >= limit ? 0.1 : loading ? 0.5 : 1,
                    color: shopVar('--shop-addToCartButtonTextColor'),
                    backgroundColor: shopVar('--shop-addToCartButtonColor'),
                }}
                disabled={loading || quantity >= limit} 
                onClick={onIncrease}>
                <RiAddLine size={20} color={'white'}/>
            </button>
        </div>
    );
} 