// File: src/hooks/usePageTracking.ts
import { useEffect } from 'react';
import { useRouter, ParsedLocation } from '@tanstack/react-router';
import { useTracking } from '../tracking/context';

export const usePageTracking = () => {
  const router = useRouter();
  const { trackEvent } = useTracking();

  useEffect(() => {
    return router.subscribe('onResolved', () => {
      trackPageView(router.state.location);
    });
  }, [router, trackEvent]);

  const trackPageView = (location: ParsedLocation) => {
    const route = location.pathname;
    const params = location.search; // TanStack Router already parses this into an object

    trackEvent({
      type: 'PAGE_VIEW',
      payload: {
        shopId: 'your-shop-id',
        path: route,
        title: document.title,
        referrer: document.referrer,
        params: params
      }
    });
  };
};