import { TrackingEvent } from "../events";
import BaseTrackingAdapter from "./BaseTrackingAdapter";
import { PixelDetector } from "./BaseTrackingAdapter";

export default class TikTokPixelAdapter extends BaseTrackingAdapter {
    async initialize(): Promise<void> {
      if (this.initialized) return;
  
      if (PixelDetector.hasTikTokPixel()) {
        // If ttq exists, just initialize our pixel ID
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).ttq.load(this.config.id);
        this.initialized = true;
        return;
      }
  
      // Load TikTok pixel script
      const script = document.createElement('script');
      script.innerHTML = `
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;
          var ttq=w[t]=w[t]||[];
          ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"];
          ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
          for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
          ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e};
          ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
          ttq.setUp=function(t){t.detailed=true};var o=d.createElement("script");o.async=!0,o.src=i,d.head.appendChild(o),
          ttq.setUp(n),ttq.instance(e)};ttq.load("${this.config.id}");ttq.page();
        }(window, document, 'ttq');
      `;
      document.head.appendChild(script);
      this.initialized = true;
    }

    async trackEvent(event: TrackingEvent): Promise<void> {
      if (!this.initialized) throw new Error('TikTok Pixel not initialized');
  
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ttq = (window as any).ttq;
  
      switch (event.type) {
        case 'PAGE_VIEW':
          ttq.track('PageView');
          break;
  
        case 'VIEW_ITEM':
          ttq.track('ViewContent', {
            content_type: 'product',
            content_id: event.payload.productId,
            content_name: event.payload.name,
            price: event.payload.price,
            currency: event.payload.currency
          });
          break;
  
        case 'ADD_TO_CART':
          ttq.track('AddToCart', {
            content_type: 'product',
            content_id: event.payload.productId,
            content_name: event.payload.name,
            price: event.payload.price * event.payload.quantity,
            currency: event.payload.currency,
            quantity: event.payload.quantity
          });
          break;
  
        case 'BEGIN_CHECKOUT':
          ttq.track('InitiateCheckout', {
            content_type: 'product',
            content_id: event.payload.items.map(item => item.productId).join(','),
            price: event.payload.totalValue,
            currency: event.payload.currency,
            quantity: event.payload.items.reduce((sum, item) => sum + item.quantity, 0)
          });
          break;
  
        case 'PURCHASE':
          ttq.track('CompletePayment', {
            content_type: 'product',
            content_id: event.payload.items.map(item => item.productId).join(','),
            value: event.payload.totalValue,
            currency: event.payload.currency,
            quantity: event.payload.items.reduce((sum, item) => sum + item.quantity, 0)
          });
          break;
  
        case 'CUSTOM':
          ttq.track(event.payload.eventName, event.payload);
          break;
      }
    }
}