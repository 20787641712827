import { createRootRoute, Outlet } from '@tanstack/react-router'
import React, { Suspense } from 'react'
import '@fontsource/inter/400.css';  // Regular weight
import '@fontsource/inter/700.css';  // Bold weight
import '@fontsource/inter/100.css';  // Thin weight
import '@fontsource/inter/200.css';  // Extra Light weight
import '@fontsource/inter/300.css';  // Light weight
import '@fontsource/inter/500.css';  // Medium weight
import '@fontsource/inter/600.css';  // Semi Bold weight
import { usePageTracking } from '@/hooks/usePageTracking';

const TanStackRouterDevtools =
  import.meta.env.VITE_MODE === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )

  function RootComponent() {
    usePageTracking(); // Initialize page tracking

    return (
      <>
        <Outlet />
        <Suspense>
          <TanStackRouterDevtools />
        </Suspense>
      </>
    );
  }
export const Route = createRootRoute({
  component: RootComponent,
})