/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * @param {{ companyName: NonNullable<unknown>, chamberOfCommerceNumber: NonNullable<unknown>, termsAndConditionsUrl: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const minimal_legal_text = (params) => `You are buying tickets from organiser <b>${params.companyName}</b> (KVK:${params.chamberOfCommerceNumber}). By completing your purchase you agree to their <a href="${params.termsAndConditionsUrl}" target="_blank" class='font-bold underline' rel="noopener noreferrer">general terms of service and privacy policy</a>`
