// File: src/tracking/adapters/custom-tracking.ts
import BaseTrackingAdapter, { PixelConfig } from './BaseTrackingAdapter';
import { TrackingEvent } from '../events';
import { UAParser } from 'ua-parser-js';
import CryptoJS from 'crypto-js';

interface FastlaneTrackingConfig extends PixelConfig {
  type: 'FASTLANE';
  endpoint: string;
  bufferEnabled?: boolean;
  bufferInterval?: number; // in milliseconds
  encryptionKey: string;
}

interface UserContext {
  timestamp: number;
  url: string;
  referrer: string;
  userAgent: {
    browser: string;
    browserVersion: string;
    engine: string;
    os: string;
    device: string;
  };
  screen: {
    width: number;
    height: number;
    colorDepth: number;
  };
  language: string;
  timezone: string;
  queryParams: Record<string, string>;
  cookies: Record<string, string>;
}

interface BufferedEvent {
  event: TrackingEvent;
  context: UserContext;
}

export class FastlaneTrackingAdapter extends BaseTrackingAdapter {
  static fromConfig(config: PixelConfig): FastlaneTrackingAdapter {
    return new FastlaneTrackingAdapter({
        type: 'FASTLANE',
        id: config.id,
        endpoint: (config.options?.endpoint as string) || 'test',
        bufferEnabled: (config.options?.bufferEnabled as boolean | undefined) || false,
        bufferInterval: (config.options?.bufferInterval as number) || 5000,
        encryptionKey: (config.options?.encryptionKey as string) || 'test'
    });
  }

  private buffer: BufferedEvent[] = [];
  private bufferTimeout: NodeJS.Timeout | null = null;
  private customConfig: FastlaneTrackingConfig;
  private uaParser: UAParser;

  constructor(config: FastlaneTrackingConfig) {
    super(config);
    this.customConfig = config;
    this.uaParser = new UAParser();
    
    // Initialize buffer flush on page hide
    if (this.customConfig.bufferEnabled) {
      window.addEventListener('visibilitychange', this.handleVisibilityChange);
      window.addEventListener('pagehide', this.flushBuffer);
    }
  }

  async initialize(): Promise<void> {
    this.initialized = true;
  }

  private handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      this.flushBuffer();
    }
  };

  private getUserContext(): UserContext {
    const ua = this.uaParser.getResult();
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams: Record<string, string> = {};
    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    // Get cookies
    const cookies: Record<string, string> = {};
    document.cookie.split(';').forEach(cookie => {
      const [key, value] = cookie.split('=').map(part => part.trim());
      cookies[key] = value;
    });

    return {
      timestamp: Date.now(),
      url: window.location.href,
      referrer: document.referrer,
      userAgent: {
        browser: ua.browser.name || 'unknown',
        browserVersion: ua.browser.version || 'unknown',
        engine: ua.engine.name || 'unknown',
        os: ua.os.name || 'unknown',
        device: ua.device.type || 'unknown'
      },
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        colorDepth: window.screen.colorDepth
      },
      language: navigator.language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      queryParams,
      cookies
    };
  }

  private encryptData(data: any): string {
    const jsonString = JSON.stringify(data);
    return CryptoJS.AES.encrypt(jsonString, this.customConfig.encryptionKey).toString();
  }

  private flushBuffer = () => {
    if (this.buffer.length === 0) return;

    const encryptedData = this.encryptData(this.buffer);
    
      fetch(this.customConfig.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          events: encryptedData
        }),
        // Use keepalive to ensure the request completes even if the page is unloading
        keepalive: true
      }).catch((error) => {
        console.error('Failed to send tracking data:', error);
      });
      
      this.buffer = [];
  };

  private scheduleBufferFlush(): void {
    if (this.bufferTimeout) {
      clearTimeout(this.bufferTimeout);
    }

    this.bufferTimeout = setTimeout(
      this.flushBuffer,
      this.customConfig.bufferInterval || 5000
    );
  }

  async trackEvent(event: TrackingEvent): Promise<void> {
    if (!this.initialized) {
      throw new Error('Custom Tracking Adapter not initialized');
    }

    console.log('Sending event to Fastlane:', event, this.getUserContext());
    return;

    const bufferedEvent: BufferedEvent = {
      event,
      context: this.getUserContext()
    };

    if (this.customConfig.bufferEnabled) {
      this.buffer.push(bufferedEvent);
      this.scheduleBufferFlush();
    } else {
        
      const encryptedData = this.encryptData([bufferedEvent]);
      await fetch(this.customConfig.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          events: encryptedData
        })
      });
    }
  }

  // Cleanup
  destroy(): void {
    if (this.bufferTimeout) {
      clearTimeout(this.bufferTimeout);
    }
    window.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('pagehide', this.flushBuffer);
  }
}