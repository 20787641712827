import { TrackingEvent } from "../events";

  // Pixel Configuration Type
  export interface PixelConfig {
    type: 'META' | 'TIKTOK' | 'GOOGLE_ANALYTICS' | 'FASTLANE';
    id: string;
    options?: Record<string, unknown>;
  }

  // Utility to check for existing pixels
export class PixelDetector {
    static hasMetaPixel(): boolean {
      return typeof (window as any).fbq !== 'undefined';
    }
  
    static hasGoogleAnalytics(): boolean {
      return typeof (window as any).gtag !== 'undefined';
    }
  
    static hasTikTokPixel(): boolean {
      return typeof (window as any).ttq !== 'undefined';
    }
  }
  
  // Base Tracking Adapter
  export interface TrackingAdapter {
    initialize(): Promise<void>;
    isInitialized(): boolean;
    trackEvent(event: TrackingEvent): Promise<void>;
  }
  
  export default abstract class BaseTrackingAdapter implements TrackingAdapter {
    protected initialized = false;
    protected config: PixelConfig;
  
    constructor(config: PixelConfig) {
      this.config = config;
    }
  
    abstract initialize(): Promise<void>;
  
    isInitialized(): boolean {
      return this.initialized;
    }
  
    abstract trackEvent(event: TrackingEvent): Promise<void>;
  }