import TikTokPixelAdapter from "./adapters/TiktokPixelAdapater";
import GoogleAnalyticsAdapter, { GoogleAnalyticsConfig } from "./adapters/GoogleAnalyticsAdapter";
import MetaPixelAdapter from "./adapters/metaPixelAdapter";
import { TrackingAdapter } from "./adapters/BaseTrackingAdapter";
import { PixelConfig } from "./adapters/BaseTrackingAdapter";

import { TrackingEvent } from "./events";
import { FastlaneTrackingAdapter } from "./adapters/FastlaneTracking";

export class TrackingProvider {
  private adapters: Map<string, TrackingAdapter> = new Map();
  private eventBuffer: Array<TrackingEvent> = [];
  private initialized = false;
  private initialConfigs: (PixelConfig|GoogleAnalyticsConfig)[];

  constructor(
    initialConfigs: (PixelConfig|GoogleAnalyticsConfig)[] = [],
    private onError?: (error: Error) => void
  ) {
    this.initialConfigs = [...initialConfigs];
    this.initializePixels(initialConfigs);
  }

  private async initializePixels(configs: (PixelConfig|GoogleAnalyticsConfig)[]) {
    for (const config of configs) {
      await this.addPixelConfig(config);
    }
    this.initialized = true;
    await this.flushBuffer();
  }

  private getPixelKey(config: PixelConfig): string {
    return `${config.type}-${config.id}`;
  }

  async addPixelConfig(config: PixelConfig|GoogleAnalyticsConfig): Promise<void> {
    const pixelKey = this.getPixelKey(config);
    
    // Check if this pixel is already initialized
    if (this.adapters.has(pixelKey)) {
      return;
    }

    try {
      let adapter: TrackingAdapter;
      
      switch (config.type) {
        case 'META':
          adapter = new MetaPixelAdapter(config);
          break;
        case 'GOOGLE_ANALYTICS':
          adapter = new GoogleAnalyticsAdapter(config as GoogleAnalyticsConfig);
          break;
        case 'TIKTOK':
          adapter = new TikTokPixelAdapter(config);
          break;
        case 'FASTLANE':
          adapter = FastlaneTrackingAdapter.fromConfig(config);
          break;
        default:
          throw new Error(`Unknown pixel type: ${config.type}`);
      }

      await adapter.initialize();
      this.adapters.set(pixelKey, adapter);
      
      if (!this.initialized) {
        this.initialized = true;
        await this.flushBuffer();
      }
    } catch (error) {
      this.onError?.(error as Error);
    }
  }

  async resetPixelConfigs(): Promise<void> {
    // Clear existing adapters
    this.adapters.clear();
    
    // Reinitialize with initial configs
    await this.initializePixels(this.initialConfigs);
  }

  async trackEvent(event: TrackingEvent): Promise<void> {
    if (!this.initialized) {
      this.eventBuffer.push(event);
      return;
    }

    await this.sendEvent(event);
  }

  private async sendEvent(
    event: TrackingEvent
  ): Promise<void> {
    const promises = Array.from(this.adapters.values()).map(adapter =>
      adapter.trackEvent(event).catch(error => this.onError?.(error))
    );

    await Promise.all(promises);
  }

  private async flushBuffer(): Promise<void> {
    const events = [...this.eventBuffer];
    this.eventBuffer = [];

    for (const event of events) {
      await this.sendEvent(event);
    }
  }
}